/* eslint-disable class-methods-use-this */
import Cookie from 'services/Cookie';
import Http from 'services/Http';
import redirect from 'utils/redirect';

const sanitize = string => `${string.replace(/\D/g, '')}`;

class Login {
  sendSMS(phone, { forceSms, keepSessionOpen, inviteToken, locale }) {
    const params = { inviteToken, phone: `+${sanitize(phone)}` };
    if (forceSms) {
      params.forceSms = 1;
    }
    params.keepSessionOpen = keepSessionOpen ? 1 : 0;
    params.locale = locale;
    return Http.post('/v1/token/request', params);
  }

  sendEmail({ name, email, company, phone }) {
    return Http.post('/v1/company/request', { userName: name, email, companyName: company, phone });
  }

  async login({ phone, password, loginType = 'sms', terms, keepSessionOpen = false }) {
    const {
      data: { user, token },
    } = await Http.post('/v1/token', {
      phone: `+${sanitize(phone)}`,
      password: loginType === 'sms' ? sanitize(password) : password,
      terms: !!terms,
      keepSessionOpen,
    });

    return { token, user };
  }

  async logout() {
    const token = Cookie.get('token');

    if (!token) return;

    await Http.delete(`/v1/token/${token.hash}`);
  }
}

export default new Login();
