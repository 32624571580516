import PropTypes from 'prop-types';
import React from 'react';
import { I18n } from 'react-redux-i18n';
import getSubdomain from 'utils/getSubdomain';

const originalTranslate = I18n.t.bind(I18n);

const replace = {
  'saint-gobain': 'Connecti@',
}[getSubdomain()];

I18n.t = (key, options) => {
  const translation = originalTranslate(key, options);
  return replace ? translation.replace(/Ommnio/g, replace) : translation;
};

const getTranslation = (translationLocale, key, replacements = {}) => {
  try {
    const translation = I18n._fetchTranslation(
      I18n._getTranslations(),
      `${translationLocale}.${key}`,
      replacements.count,
    );
    return I18n._replace(translation, replacements);
  } catch (err) {
    return I18n._handleMissingTranslation(key, replacements);
  }
};

const Translate = ({ value, dangerousHTML, tag: Tag = 'span', locale = null, ...otherProps }) => {
  const translation = locale
    ? getTranslation(locale, value, otherProps)
    : I18n.t(value, otherProps);

  if (dangerousHTML) {
    return <Tag dangerouslySetInnerHTML={{ __html: translation }} {...otherProps} />;
  }
  return <Tag {...otherProps}>{translation}</Tag>;
};

Translate.propTypes = {
  value: PropTypes.string.isRequired,
  tag: PropTypes.oneOfType([PropTypes.func, PropTypes.string]),
  dangerousHTML: PropTypes.bool,
  locale: PropTypes.string,
};

Translate.defaultProps = {
  tag: 'span',
  dangerousHTML: false,
  locale: null,
};

export { I18n, Translate };
