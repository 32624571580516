/* eslint-disable class-methods-use-this */
import Http from 'services/Http';

class Channel {
  async find(id) {
    const { data } = await Http.get(`/v1/group/${id}`);
    return data.group;
  }

  async list() {
    const { data } = await Http.get('/v1/group');
    return data;
  }

  async create(form) {
    const response = await Http.post('/v1/group', form);
    return response.data.group;
  }

  async update(channelId, form) {
    const response = await Http.patch(`/v1/group/${channelId}`, form);
    return response.data.group;
  }

  async updatePreview(channelId, form) {
    const response = await Http.patch(`/v1/group/${channelId}/update-preview`, form);
    return response.data.group;
  }

  async join(channelId) {
    const response = await Http.patch(`/v1/group/${channelId}/join`);
    return response.data.group;
  }

  async upload(id, avatar) {
    const form = new FormData();
    form.append('avatar', avatar);
    const { data } = await Http.post(`/v1/group/${id}/file`, form);
    return data;
  }

  async destroy(id) {
    const { data } = await Http.delete(`/v1/group/${id}`);
    return data;
  }

  async setArchived(id, archived) {
    const { data } = await Http.patch(`/v1/group/${id}/${archived ? '' : 'un'}archive`);
    return data;
  }

  async setMuted(id, muted) {
    const { data } = await Http.patch(`/v1/group/${id}/mute`, { muted });
    return data;
  }

  async setPinned(id, pinned) {
    const { data } = await Http.patch(`/v1/group/${id}/pinned`, { pinned });
    return data;
  }

  async getMemberChannels() {
    const { data } = await Http.get('/v1/group/member');
    return data;
  }

  async getMembers(id, { skip, limit, search, message }) {
    const { data } = await Http.get(`/v1/group/${id}/members`, {
      params: { skip, limit, search, message },
    });
    return data;
  }
}

export default new Channel();
